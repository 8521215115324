<template>
    <form novalidate class="flex flex-col gap-3" @submit="onSubmit">
        <div v-if="!isSent" class="flex w-full max-w-xs">
            <Input
                v-model="email"
                v-bind="emailAttrs"
                name="newsletter-mail"
                :placeholder="$t('newsletter.placeholder')"
                :error="errors.email" />
            <Button
                type="submit"
                color="blue"
                intent="primary"
                class="z-10 -ml-6 h-max"
                :disabled="(!meta.valid && meta.touched) || (meta.validated && !meta.valid)"
                >{{ $t('newsletter.sign_up') }}</Button
            >
        </div>
        <div v-else>
            <p class="text-lg font-bold">{{ $t('newsletter.success') }}</p>
        </div>
    </form>
</template>

<script setup lang="ts">
    import { ActiveCampaignCountry } from '~/types/enums/ActiveCampaignCountry';
    import { object as yupObject, string as yupString } from 'yup';
    const { t } = useI18n();

    const currentCountry = useCurrentCountry();
    const { $posthog } = useNuxtApp();

    const isSent = ref(false);

    const formSchema = toTypedSchema(
        yupObject({
            email: yupString().required(t('form.errors.required')).email(t('form.errors.email')),
        }),
    );

    const { meta, errors, defineField, handleSubmit } = useForm({
        validationSchema: formSchema,
    });

    const [email, emailAttrs] = defineField('email');

    const onSubmit = handleSubmit(async (values) => {
        if (values.email.trim() === '') {
            return;
        }

        await $fetch('/api/cms/newsletter', {
            method: 'POST',
            body: {
                email: values.email,
                country:
                    ActiveCampaignCountry[
                        currentCountry.value.toUpperCase() as keyof typeof ActiveCampaignCountry
                    ],
            },
        });
        isSent.value = true;

        // Send Newsletter event to posthog
        const posthog = $posthog();
        if (posthog) {
            posthog.capture('Newsletter Subscription', {
                email: values.email,
            });
        }
    });
</script>
